import React from "react";
import classes from "./Loader.module.css";

const Loader = ({ width, height, dark }) => {
  return (
    <span
      className={
        dark
          ? `${classes.loading} ${classes.dark}  d-inline-block`
          : `${classes.loading} d-inline-block ${classes.white}`
      }
      style={{ width: `${width}px`, height: `${height}px` }}
    ></span>
  );
};

export default Loader;
